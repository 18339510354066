<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class="h-100 d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
      </div>
    </div>
    <div class="auth-side-form">
      <div class=" auth-content">
        <img src="assets/images/QMS BIlling Logo FA v7-03_new.png" alt="" class="img-fluid mb-4 d-block d-xl d-lg">
        <div class="input-group mb-4">
          <h4 class="mb-4 f-w-400">Change your password</h4>
          <div class="input-group mb-4">
            <input type="password" class="form-control" placeholder="Current Password" (change)="change()"
              (blur)="cpCheck()" [(ngModel)]="currentPassword">
          </div>
          <p *ngIf="cpErrorFlag" style="color: rgb(223, 75, 69); font-style:italic">Incorrect Password</p>
          <div class="input-group mb-4">
            <input type="password" class="form-control" placeholder="New Password" (change)="change()"
              (blur)="npCheck()" [(ngModel)]="newPassword">
          </div>
          <div class="input-group mb-4">
            <input type="password" class="form-control" placeholder="Re-Type New Password" [(ngModel)]="confirmPassword"
              (change)="change()" (blur)="npCheck()">
          </div>
          <p *ngIf="npErrorFlag" style="color: rgb(223, 75, 69); font-style:italic">Passwords needs to match</p>
        </div>
        <p *ngIf="fieldEmptyErrorFlag" style="color: rgb(223, 75, 69); font-style:italic">All fields are required</p>
        <button *ngIf="!spinner" class="btn btn-block btn-primary mb-0" (click)="changePassword()">Change
          password
        </button>
        <div *ngIf="spinner" class="d-flex justify-content-center">
          <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-success" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-danger" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-dark" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <br>
        <button *ngIf="!spinner" class="btn btn-block btn-success mb-0" (click)="back()">Back</button>
        <div class="text-center">
          <!-- <div class="saprator my-4"><span>OR</span></div>
          <button class="btn text-white bg-facebook mb-2 mr-2  wid-40 px-0 hei-40 rounded-circle"><i class="fab fa-facebook-f"></i></button>
          <button class="btn text-white bg-googleplus mb-2 mr-2 wid-40 px-0 hei-40 rounded-circle"><i class="fab fa-google-plus-g"></i></button>
          <button class="btn text-white bg-twitter mb-2  wid-40 px-0 hei-40 rounded-circle"><i class="fab fa-twitter"></i></button> -->
        </div>
      </div>
    </div>
  </div>
</div>