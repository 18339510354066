<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class="h-100 d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
        <!-- <img src="assets/images/qmsLogo.png" alt="" class="img-fluid"> -->
        <!-- <h1 class="text-white my-4">Welcome Back!</h1> -->
        <!-- <h4 class="text-white font-weight-normal">Signin to your account.</h4> -->
      </div>
    </div>
    <div class="auth-side-form">
      <div class=" auth-content" (keyup.enter)="login()" >
        <!-- <img src="assets/images/qmsmenu.png" alt="" class="img-fluid mb-4 d-block d-xl d-lg"> -->
        <img src="assets/images/QMS BIlling Logo FA v7-03_new.png" alt="" class="img-fluid mb-4 d-block d-xl d-lg">
        <h3 class="mb-4 f-w-400">Sign In</h3>
        <div class="input-group mb-4">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="feather icon-mail"></i></span>
          </div>
          <input type="email" class="form-control" placeholder="Email address" [(ngModel)]="userName">
        </div>
        <div class="input-group mb-4">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="feather icon-lock"></i></span>
          </div>
          <input type="password" class="form-control" placeholder="Password" [(ngModel)]="password">
        </div>
        <!-- <div class="form-group">
          <label *ngIf="errorFlag" class="error jquery-validation-error small form-text invalid-feedback ng-star-inserted">
            Login credentials mismatch
          </label>
        </div> -->
        <p *ngIf="errorFlag" style="color: rgb(223, 75, 69); font-style:italic">Login credentials mismatch</p>
        <!-- <div class="form-group text-left mt-2">
          <div class="checkbox checkbox-primary d-inline">
            <input type="checkbox" name="checkbox-p-1" id="checkbox-p-1" checked="">
            <label for="checkbox-p-1" class="cr">Save credentials</label>
          </div>
        </div> -->
        <button class="btn btn-block btn-primary mb-0" (click)=login()> Sign In</button>
        <br>
        <button class="btn btn-block btn-danger mb-0" (click)=forgotPassword()> Forgot Password</button>
        <div class="text-center">
          <!-- <div class="saprator my-4"><span>OR</span></div>
          <button class="btn text-white bg-facebook mb-2 mr-2  wid-40 px-0 hei-40 rounded-circle"><i class="fab fa-facebook-f"></i></button>
          <button class="btn text-white bg-googleplus mb-2 mr-2 wid-40 px-0 hei-40 rounded-circle"><i class="fab fa-google-plus-g"></i></button>
          <button class="btn text-white bg-twitter mb-2  wid-40 px-0 hei-40 rounded-circle"><i class="fab fa-twitter"></i></button> -->
          <!-- <p class="mb-2 text-muted">Forgot password? <a [routerLink]="['/auth/reset-password-v2']" class="f-w-400">Reset</a></p>
          <p class="mb-0 text-muted">Don’t have an account? <a [routerLink]="['/auth/signup-v2']" class="f-w-400">Signup</a></p> -->
        </div>
      </div>
    </div>
  </div>
</div>