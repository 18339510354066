import { Component, OnInit } from "@angular/core";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { User } from "../../../pages/home/user-management/model/user";
import Swal from "sweetalert2";
@Component({
  selector: "auth-login",
  templateUrl: "./auth-login.component.html",
  styleUrls: ["./auth-login.component.scss"],
})
export class AuthLoginComponent implements OnInit {
  public errorFlag: boolean = false;

  public userName = "";
  public password = "";
  user: User;
  constructor(public authService: AuthService, private _router: Router) {}

  ngOnInit() {
    this.user = new User();
    this.errorFlag = false;
  }

  login() {
    console.log("login...", this.userName, this.password);
    this.authService.login(this.userName, this.password).subscribe(
      (x) => {
        console.log("service login credentials..", x);
        this.user = x;
        //
        this.errorFlag = false;
        if (this.user != null) {
          console.log("User Exists");

          if (this.user.id > 0) {
            console.log("User id is greater tha 0");
            if (this.user.userDetails.length > 0) {
              console.log("userDetails :", this.user.userDetails);
              if (this.user.userDetails[0] != undefined) {
                console.log(
                  "userDetails[0] is defined:",
                  this.user.userDetails[0]
                );
                if (
                  this.user.userDetails[0].userGroupId != undefined &&
                  this.user.userDetails[0].userGroupId > 0
                ) {
                  console.log("localstorage set karo");
                  localStorage.setItem("currentUser", JSON.stringify(x));
                  this._router.navigate(["/dashboard"]);
                  this.errorFlag = false;
                  localStorage.setItem("currentUser", JSON.stringify(x));
                  console.log(
                    "return from LC :",
                    localStorage.getItem("currentUser")
                  );
                } else {
                  this.redirectToLogin();
                }
              } else {
                this.redirectToLogin();
              }
            } else {
              this.redirectToLogin();
            }
            localStorage.setItem("currentUser", JSON.stringify(x));
          }
        } else {
          this.errorFlag = true;
        }
        //
      },
      (error) => (this.errorFlag = true)
    );
  }
  public redirectToLogin() {
    Swal.fire(
      "",
      "User Group not assigned! Please contact administrator!!!",
      "error"
    );
  }
  forgotPassword() {
    this._router.navigate(["/auth/reset-password"]);
  }
}
