import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
// import {AuthComponent} from './theme/layout/auth/auth.component'; 


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../app/pages/home/pages.module').then(module => module.PagesModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./theme/layout/auth/auth.module').then(module => module.AuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
