import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import 'rxjs/add/operator/switchMap';
import { AuthService } from './auth.service';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-auth-reset-password-v2',
  templateUrl: './auth-reset-password-v2.component.html',
  styleUrls: ['./auth-reset-password-v2.component.scss']
})
export class AuthResetPasswordV2Component implements OnInit {

  constructor(private authService: AuthService, private _router: Router, private route: ActivatedRoute) { }

  public user = {
    email: '', keyForPasswordChange: '', password: ''
  };
  public key: Boolean = false
  public passwordKey: string = '';
  public newPassword: string = '';
  public confirmPassword: string = '';
  public npErrorFlag: Boolean = false;
  public spinner: Boolean = false;
  public validEmail: Boolean = true;
  public emailNotExistFlag: Boolean = false;
  public invalidKeyErrorFlag: Boolean = false;
  public fieldEmptyErrorFlag: Boolean = false;
  public disableSave: Boolean = true;

  ngOnInit() {

  }

  npCheck() {
    // if (this.confirmPassword != '') {
    //   if (this.newPassword != this.confirmPassword) {
    //     this.npErrorFlag = true
    //     this.disableSave = true
    //   } else {
    //     this.npErrorFlag = false
    //     this.disableSave = false
    //   }
    // }
  }

  generateKeyResetPassword() {
    this.emailNotExistFlag = false
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.validEmail = re.test(String(this.user.email).toLowerCase());
    if (!this.validEmail) {
      // Swal.fire('', 'Please enter valid Email Id', 'error');
    } else {
      this.spinner = true
      this.authService.generateKeyResetPassword(this.user).subscribe(x => {
        if (x.id == null) {
          this.emailNotExistFlag = true
          this.spinner = false
        }
        else {
          this.emailNotExistFlag = false
          this.key = true;
          this.spinner = false
        }
      })
    }
  }

  savePassword() {
    this.fieldEmptyErrorFlag = false;
    this.invalidKeyErrorFlag = false;
    if (this.newPassword == '' || this.confirmPassword == '' || this.user.keyForPasswordChange == '') {
      this.fieldEmptyErrorFlag = true
      this.disableSave = true
      this.invalidKeyErrorFlag = false;
    } else if (this.confirmPassword != '') {
      if (this.newPassword != this.confirmPassword) {
        this.npErrorFlag = true
        this.disableSave = true
      } else {
        this.npErrorFlag = false
        this.disableSave = false
      }
    }
    if (!this.disableSave) {
      this.user.password = this.newPassword
      this.spinner = true
      this.authService.resetPasswordByKey(this.user).subscribe(x => {
        if (x == null) {
          this.invalidKeyErrorFlag = true;
          this.spinner = false;
        } else {
          Swal.fire('', 'Password Updated', 'success');
          history.back();
        }
      })
    }
  }

  back() {
    history.back();
  }

}
