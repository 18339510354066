import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth-change-password-v2',
  templateUrl: './auth-change-password-v2.component.html',
  styleUrls: ['./auth-change-password-v2.component.scss']
})
export class AuthChangePasswordV2Component implements OnInit {

  public currentUser: any;
  public currentPassword: string = '';
  public newPassword: string = '';
  public confirmPassword: string = '';
  public cpErrorFlag: Boolean = false;
  public npErrorFlag: Boolean = false;
  public fieldEmptyErrorFlag: Boolean = false;
  public cpDisable: Boolean = true;
  public spinner: Boolean = false;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
  }

  cpCheck() {
    this.cpDisable = false
    if (this.currentUser.password != this.currentPassword) {
      this.cpErrorFlag = true
    } else {
      this.cpErrorFlag = false
    }
  }

  npCheck() {
    if (this.confirmPassword != '') {
      if (this.newPassword != this.confirmPassword) {
        this.npErrorFlag = true
      } else {
        this.npErrorFlag = false
      }
    }
  }

  change() {
    this.cpDisable = false
    this.fieldEmptyErrorFlag = false
  }

  changePassword() {
    if (this.cpErrorFlag == true || this.npErrorFlag == true) {
      this.cpDisable = true;
    } else if (this.currentPassword == '' || this.newPassword == '' || this.confirmPassword == '') {
      this.cpDisable = true;
      this.fieldEmptyErrorFlag = true;
    } else {
      if (this.newPassword == this.confirmPassword && !this.cpDisable) {
        this.fieldEmptyErrorFlag = false;
        this.currentUser.password = this.newPassword
        this.spinner = true;
        this.authService.changePassword(this.currentUser).subscribe(x => {
          localStorage.setItem('currentUser', JSON.stringify(x));
          history.back();
        })
      }
    }
  }

  back() {
    history.back()
  }

}
