<ul class="navbar-nav ml-auto">
    <li>
        <span>VERSION 2.5.3</span>
    </li>
    <li>
        <div class="dropdown drp-user" ngbDropdown placement="auto">
            <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
                <img src="assets/images/user/avatar-1.jpg" class="img-radius wid-40" alt="User-Profile-Image">
            </a>
            <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
                <div class="pro-head">
                    <img src="assets/images/user/avatar-1.jpg" class="img-radius" alt="User-Profile-Image">
                    <br />
                    <br />
                    <span>{{userName}}</span>
                    <a href="javascript:" class="dud-logout" (click)="logout()" title="Logout">
                        <i class="feather icon-log-out"></i>
                    </a>
                </div>
                <ul class="pro-body">
                    <li><a href="javascript:" class="dropdown-item"><i class="feather icon-user"></i> Profile</a></li>
                    <li><a href="javascript:" class="dropdown-item" (click)="changePassword()"><i class="feather icon-user"></i>
              Change
              Password</a></li>
                    <!-- <li><a href="javascript:" class="dropdown-item"><i class="feather icon-mail"></i> My Messages</a></li>
          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-lock"></i> Lock Screen</a></li> -->
                </ul>
            </div>
        </div>
    </li>
</ul>