import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthLoginComponent } from './auth-login.component';
import { AuthResetPasswordV2Component } from './auth-reset-password-v2.component';
import { AuthChangePasswordV2Component } from './auth-change-password-v2.component';
import { AuthService } from "./auth.service";

@NgModule({
  declarations: [AuthLoginComponent, AuthResetPasswordV2Component, AuthChangePasswordV2Component],
  imports: [
    CommonModule,
    FormsModule,
    AuthRoutingModule
  ],
  providers: [AuthService]
})
export class AuthModule { }
