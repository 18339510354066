<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class="h-100 d-md-flex align-items-center auth-side-img">

    </div>
    <div class="auth-side-form">
      <div class=" auth-content">
        <img src="assets/images/QMS BIlling Logo FA v7-03_new.png" alt="" class="img-fluid mb-4 d-block d-xl d-lg">
        <h3 class="mb-4 f-w-400">Reset your password</h3>
        <div *ngIf="!this.key" class="input-group mb-4">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="feather icon-mail"></i></span>
          </div>
          <input type="email" class="form-control" placeholder="Email address" [(ngModel)]="user.email">
        </div>
        <div *ngIf="!this.key">
          <p *ngIf="!validEmail" style="color: rgb(223, 75, 69); font-style:italic">Enter Valid Email Id</p>
          <p *ngIf="emailNotExistFlag" style="color: rgb(223, 75, 69); font-style:italic">Email Id Does not Exist</p>
        </div>
        <div *ngIf="this.key" class="input-group mb-4">
          <input type="text" class="form-control" placeholder="Reset Password Key"
            [(ngModel)]="user.keyForPasswordChange">
        </div>
        <div *ngIf="this.key" class="input-group mb-4">
          <input type="password" class="form-control" placeholder="New Password" (blur)="npCheck()"
            [(ngModel)]="newPassword">
        </div>
        <div *ngIf="this.key" class="input-group mb-4">
          <input type="password" class="form-control" placeholder="Re-Type New Password" [(ngModel)]="confirmPassword"
            (blur)="npCheck()">
        </div>
        <div *ngIf="this.key">
          <p *ngIf="fieldEmptyErrorFlag" style="color: rgb(223, 75, 69); font-style:italic">All fields are required</p>
          <p *ngIf="npErrorFlag" style="color: rgb(223, 75, 69); font-style:italic">Passwords needs to match</p>
          <p *ngIf="invalidKeyErrorFlag" style="color: rgb(223, 75, 69); font-style:italic">Invalid Key</p>
        </div>
        <div *ngIf="!spinner">
          <button *ngIf="this.key" class="btn btn-block btn-primary mb-0" (click)="savePassword()">Save
            password</button>
          <button *ngIf="!this.key" class="btn btn-block btn-primary mb-0" (click)="generateKeyResetPassword()">Reset
            password</button>
          <br>
          <button class="btn btn-block btn-success mb-0" (click)="back()">Back</button>
        </div>
        <div *ngIf="spinner" class="d-flex justify-content-center">
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-success" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-danger" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-dark" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="text-center">
          <!-- <div class="saprator my-4"><span>OR</span></div>
          <button class="btn text-white bg-facebook mb-2 mr-2  wid-40 px-0 hei-40 rounded-circle"><i
              class="fab fa-facebook-f"></i></button>
          <button class="btn text-white bg-googleplus mb-2 mr-2 wid-40 px-0 hei-40 rounded-circle"><i
              class="fab fa-google-plus-g"></i></button>
          <button class="btn text-white bg-twitter mb-2  wid-40 px-0 hei-40 rounded-circle"><i
              class="fab fa-twitter"></i></button>
          <p class="mb-0 text-muted">Don’t have an account? <a [routerLink]="['/auth/signup-v2']"
              class="f-w-400">Signup</a></p> -->
        </div>
      </div>
    </div>
  </div>
</div>