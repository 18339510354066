import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import { HttpClient } from '@angular/common/http';
import "rxjs/add/operator/map";
import { Observable } from 'rxjs/Rx'
import { environment } from '../../../../environments/environment';
import { User } from "./model/user";

@Injectable({ providedIn: 'root' })
export class AuthService {

    serviceApiUrl: string = environment.serviceApiUrl;
    user: User;

    constructor(private http: HttpClient) {
        this.user = new User();
    }

    private jwt() {
        let headers = new Headers({ 'Accept': 'application/json', 'Content-Type': 'application/json' });
        return new RequestOptions({ headers: headers });
    }

    login(email: string, password: string) {
        let obj = JSON.stringify({ userName: email, password: password });

        return this.http.post<User>(this.serviceApiUrl + 'user/login', obj)


    }

    isAuthenticated(): boolean {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            return true;
        } else {
            return false;
        }
    }

    changePassword(user) {
        return this.http.put(this.serviceApiUrl + 'user/update', user)
    }

    generateKeyResetPassword(user) {
        return this.http.post<any>(this.serviceApiUrl + 'user/generate-key-to-reset-password', user)
    }

    resetPasswordByKey(user) {
        return this.http.put(this.serviceApiUrl + 'user/reset-password', user);
    }


}