import { Injectable } from '@angular/core';
import { UserManagementService } from "../../../../pages/home/user-management/user-management.service";
import { PagesService } from "../../../../pages/home/pages.service";
export interface NavigationItem {
  id: string;
  title: string;
  name: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: '',
    type: 'group',
    icon: 'feather icon-layers',
    children: [
      {
        id: 'dashboard-progress',
        title: 'Dashboard',
        name: 'Dashboard',
        type: 'item',
        icon: 'feather icon-layers',
        url: '/dashboard-progress',
        breadcrumbs: false,
        hidden: true,
      },
      {
        id: 'Incremental-Approval',
        title: 'Incremental Approval',
        type: 'collapse',
        breadcrumbs: true,
        hidden: true,
        icon: 'feather icon-file-text',
        children: [
          {
            id: 'File-Selection',
            title: 'Pending Approvals',
            name: 'Pending Approvals',
            type: 'item',
            url: '/incremental-approval/file-selection',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'History',
            title: 'Approval History',
            name: 'Approval History',
            type: 'item',
            url: '/incremental-approval/history',
            breadcrumbs: true,
            hidden: true,
          },
        ]
      },
      {
        id: 'master',
        title: 'Master',
        type: 'collapse',
        icon: 'feather icon-settings',
        hidden: true,
        children: [
          {
            id: 'state',
            title: 'State',
            name: 'State',
            type: 'item',
            url: '/master/state-list',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'district',
            title: 'District',
            name: 'District',
            type: 'item',
            url: '/master/district-list',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'circle',
            title: 'Circle',
            name: 'Circle',
            type: 'item',
            url: '/master/circle-list',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'clinic',
            title: 'Clinic',
            name: 'Clinic',
            type: 'item',
            url: '/master/clinic-list',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'invoice-type',
            title: 'Invoice Type',
            name: 'Invoice Type',
            type: 'item',
            url: '/master/invoice-type-list',
            breadcrumbs: true,
            hidden: true,
          }
        ]
      },
      {
        id: 'user-management',
        title: 'User Management',
        type: 'collapse',
        icon: 'feather icon-users',
        hidden: true,
        children: [
          {
            id: 'user',
            title: 'User',
            name: 'User',
            type: 'item',
            url: '/user-management/user',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'user-group-master',
            title: 'Set Group Level',
            name: 'Set Group Level',
            type: 'item',
            url: '/user-management/user/user-group-master',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'user-group',
            title: 'Create Group',
            name: 'Create Group',
            type: 'item',
            url: '/user-management/user/user-group',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'user-role',
            title: 'User Role',
            name: 'User Role',
            type: 'item',
            url: '/user-management/user/user-role',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'user-group-role-mapping',
            title: 'Group Role Mapping',
            name: 'Group Role Mapping',
            type: 'item',
            url: '/user-management/user/user-group-role-mapping',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'user-group-menu-mapping',
            title: 'Group Menu Mapping',
            name: 'Group Menu Mapping',
            type: 'item',
            url: '/user-management/user/user-group-menu-mapping',
            breadcrumbs: true,
            hidden: true,
          }
        ]
      },
      {
        id: 'invoice-management',
        title: 'Invoice Management',
        type: 'collapse',
        icon: 'feather icon-file',
        hidden: true,
        children: [
          {
            id: 'fin-01-list-page',
            title: 'FIN 01',
            name: 'FIN 01 - Purchase of New BME',
            type: 'item',
            // icon: 'feather icon-file-text',
            url: '/transaction/fin-01-invoice/fin-01-list-page',
            breadcrumbs: true,
            hidden: true,
          },
          //mine
          {
            id: 'fin-01-list-page',
            title: 'FIN 01',
            name: 'FIN 01 - Purchase of New BME',
            type: 'collapse',
            url: '/transaction/fin-01-invoice/fin-01-list-page',
            breadcrumbs: true,
            hidden: true,
            children: [
              {
                id: 'create-fin-06',
                title: 'Fin 06 Create',
                name: 'Fin 06 Create',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-06-create-list/new',
                breadcrumbs: true
              },
              {
                id: 'create-fin-06',
                title: 'Fin 06 Create',
                name: 'Fin 06 Create',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-06-create-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-06-inprogress',
                title: 'Fin 06 In Progress',
                name: 'Fin 06 In Progress',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-06-inprogress/new',
                breadcrumbs: true
              },
              {
                id: 'fin-06-inprogress',
                title: 'Fin 06 In Progress',
                name: 'Fin 06 In Progress',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-06-inprogress/older',
                breadcrumbs: true
              },
              {
                id: 'fin-06-approved',
                title: 'Fin 06 Approved',
                name: 'Fin 06 Approved',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-06-approved/new',
                breadcrumbs: true
              },
              {
                id: 'fin-06-approved',
                title: 'Fin 06 Approved',
                name: 'Fin 06 Approved',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-06-approved/older',
                breadcrumbs: true
              },
              {
                id: 'create-fin-01',
                title: 'Fin 01 Create',
                name: 'Fin 01 Create',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-01-create-list/new',
                breadcrumbs: true
              },
              {
                id: 'create-fin-01',
                title: 'Fin 01 Create',
                name: 'Fin 01 Create',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-01-create-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-01-inprogress-list',
                title: 'Fin 01 In Progress',
                name: 'Fin 01 In Progress',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-01-inprogress-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-01-inprogress-list',
                title: 'Fin 01 In Progress',
                name: 'Fin 01 In Progress',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-01-inprogress-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-01-approved-list',
                title: 'Fin 01 Approved',
                name: 'Fin 01 Approved',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-01-approved-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-01-approved-list',
                title: 'Fin 01 Approved',
                name: 'Fin 01 Approved',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-01-approved-list/older',
                breadcrumbs: true
              },
              {
                id: 'create-FIN-01',
                title: 'FIN 01 Create',
                name: 'FIN 01 Create',
                type: 'item',
                url: '/transaction/fin-01-invoice/FIN-01-create-list/new',
                breadcrumbs: true
              },
              {
                id: 'create-FIN-01',
                title: 'FIN 01 Create',
                name: 'FIN 01 Create',
                type: 'item',
                url: '/transaction/fin-01-invoice/FIN-01-create-list/older',
                breadcrumbs: true
              },
              {
                id: 'FIN-01-inprogress-list',
                title: 'FIN 01 In Progress',
                name: 'FIN 01 In Progress',
                type: 'item',
                url: '/transaction/fin-01-invoice/FIN-01-inprogress-list/new',
                breadcrumbs: true
              },
              {
                id: 'FIN-01-inprogress-list',
                title: 'FIN 01 In Progress',
                name: 'FIN 01 In Progress',
                type: 'item',
                url: '/transaction/fin-01-invoice/FIN-01-inprogress-list/older',
                breadcrumbs: true
              },
              {
                id: 'FIN-01-approved-list',
                title: 'FIN 01 Approved',
                name: 'FIN 01 Approved',
                type: 'item',
                url: '/transaction/fin-01-invoice/FIN-01-approved-list/new',
                breadcrumbs: true
              },
              {
                id: 'FIN-01-approved-list',
                title: 'FIN 01 Approved',
                name: 'FIN 01 Approved',
                type: 'item',
                url: '/transaction/fin-01-invoice/FIN-01-approved-list/older',
                breadcrumbs: true
              },
              {
                id: 'Fin-01-inv-exceptoin',
                title: 'Fin 01 Inv Exception',
                name: 'Fin 01 Inv Exception',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-01-inv-exception/new',
                breadcrumbs: true
              },
              {
                id: 'Fin-01-inv-exceptoin',
                title: 'Fin 01 Inv Exception',
                name: 'Fin 01 Inv Exception',
                type: 'item',
                url: '/transaction/fin-01-invoice/fin-01-inv-exception/older',
                breadcrumbs: true
              },
              {
                id: 'FIN-01-inv-exception',
                title: 'FIN 01 Inv Exception',
                name: 'FIN 01 Inv Exception',
                type: 'item',
                url: '/transaction/fin-01-invoice/FIN-01-inv-exception/new',
                breadcrumbs: true
              },
              {
                id: 'FIN-01-inv-exception',
                title: 'FIN 01 Inv Exception',
                name: 'FIN 01 Inv Exception',
                type: 'item',
                url: '/transaction/fin-01-invoice/FIN-01-inv-exception/older',
                breadcrumbs: true
              }
            ]
          }
          //mine
          ,
          {
            id: 'fin-02a-list-page',
            title: 'FIN 02A',
            type: 'item',
            name: 'FIN 02A - Existing BME Rental Charges',
            // icon: 'feather icon-file-text',
            url: '/transaction/fin-02a-invoice/fin-02a-list-page',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'fin-02a-list-page',
            title: 'FIN 02A',
            name: 'FIN 02A - Existing BME Rental Charges',
            type: 'collapse',
            url: '/transaction/fin-02a-invoice/fin-02a-list-page',
            breadcrumbs: true,
            hidden: true,
            children: [
              {
                id: 'create-fin-07',
                title: 'Fin 07 Create',
                name: 'Fin 07 Create',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-07-create-list/new',
                breadcrumbs: true
              },
              {
                id: 'create-fin-07',
                title: 'Fin 07 Create',
                name: 'Fin 07 Create',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-07-create-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-07-inprogress-list',
                title: 'Fin 07 In Progress',
                name: 'Fin 07 In Progress',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-07-inprogress-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-07-inprogress-list',
                title: 'Fin 07 In Progress',
                name: 'Fin 07 In Progress',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-07-inprogress-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-07-approved-list',
                title: 'Fin 07 Approved',
                name: 'Fin 07 Approved',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-07-approved-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-07-approved-list',
                title: 'Fin 07 Approved',
                name: 'Fin 07 Approved',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-07-approved-list/older',
                breadcrumbs: true
              },
              {
                id: 'create-fin-03a',
                title: 'Fin 03a Create',
                name: 'Fin 03a Create',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-03a-create-list/new',
                breadcrumbs: true
              },
              {
                id: 'create-fin-03a',
                title: 'Fin 03a Create',
                name: 'Fin 03a Create',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-03a-create-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-03a-inprogress-list',
                title: 'Fin 03a In Progress',
                name: 'Fin 03a In Progress',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-03a-inprogress-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-03a-inprogress-list',
                title: 'Fin 03a In Progress',
                name: 'Fin 03a In Progress',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-03a-inprogress-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-03a-approved-list',
                title: 'Fin 03a Approved',
                name: 'Fin 03a Approved',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-03a-approved-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-03a-approved-list',
                title: 'Fin 03a Approved',
                name: 'Fin 03a Approved',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-03a-approved-list/older',
                breadcrumbs: true
              },
              {
                id: 'create-fin-02a',
                title: 'FIN 02A Create',
                name: 'FIN 02A Create',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-02a-create-list/new',
                breadcrumbs: true
              },
              {
                id: 'create-fin-02a',
                title: 'FIN 02A Create',
                name: 'FIN 02A Create',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-02a-create-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-02a-inprogress-list',
                title: 'FIN 02A In Progress',
                name: 'FIN 02A In Progress',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-02a-inprogress-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-02a-inprogress-list',
                title: 'FIN 02A In Progress',
                name: 'FIN 02A In Progress',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-02a-inprogress-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-02a-approved-list',
                title: 'FIN 02A Approved',
                name: 'FIN 02A Approved',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-02a-approved-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-02a-approved-list',
                title: 'FIN 02A Approved',
                name: 'FIN 02A Approved',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-02a-approved-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-03a-inv-exceptoin',
                title: 'Fin 03A Inv Exception',
                name: 'Fin 03A Inv Exception',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-03a-inv-exception/new',
                breadcrumbs: true
              },
              {
                id: 'fin-03a-inv-exceptoin',
                title: 'Fin 03A Inv Exception',
                name: 'Fin 03A Inv Exception',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-03a-inv-exception/older',
                breadcrumbs: true
              },
              {
                id: 'fin-02a-inv-exception',
                title: 'FIN 02A Inv Exception',
                name: 'FIN 02A Inv Exception',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-02a-inv-exception/new',
                breadcrumbs: true
              },
              {
                id: 'fin-02a-inv-exception',
                title: 'FIN 02A Inv Exception',
                name: 'FIN 02A Inv Exception',
                type: 'item',
                url: '/transaction/fin-02a-invoice/fin-02a-inv-exception/older',
                breadcrumbs: true
              }
            ]
          },
          {
            id: 'fin-02-list-page',
            title: 'FIN 02',
            name: 'FIN 02 - New and Purchased BME Maintenance Charge',
            type: 'item',
            // icon: 'feather icon-file-text',
            url: '/transaction/fin-02-invoice/fin-02-list-page',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'fin-02-list-page',
            title: 'FIN 02',
            name: 'FIN 02 - New and Purchased BME Maintenance Charge',
            type: 'collapse',
            url: '/transaction/fin-02-invoice/fin-02-list-page',
            breadcrumbs: true,
            hidden: true,
            children: [
              {
                id: 'create-fin-08b',
                title: 'Fin 08b Create',
                name: 'Fin 08b Create',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08/fin-08b-create-list/new',
                breadcrumbs: true
              },
              {
                id: 'create-fin-08b',
                title: 'Fin 08b Create',
                name: 'Fin 08b Create',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08/fin-08b-create-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-08b-inprogress-list',
                title: 'Fin 08b In Progress',
                name: 'Fin 08b In Progress',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08/fin-08b-inprogress-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-08b-inprogress-list',
                title: 'Fin 08b In Progress',
                name: 'Fin 08b In Progress',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08/fin-08b-inprogress-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-08b-approved-list',
                title: 'Fin 08b Approved',
                name: 'Fin 08b Approved',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08/fin-08b-approved-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-08b-approved-list',
                title: 'Fin 08b Approved',
                name: 'Fin 08b Approved',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08/fin-08b-approved-list/older',
                breadcrumbs: true
              },
              {
                id: 'create-fin-08c',
                title: 'Fin 08c Create',
                name: 'Fin 08c Create',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08/fin-08c-create-list/new',
                breadcrumbs: true
              },
              {
                id: 'create-fin-08c',
                title: 'Fin 08c Create',
                name: 'Fin 08c Create',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08/fin-08c-create-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-08c-inprogress-list',
                title: 'Fin 08c In Progress',
                name: 'Fin 08c In Progress',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08/fin-08c-inprogress-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-08c-inprogress-list',
                title: 'Fin 08c In Progress',
                name: 'Fin 08c In Progress',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08/fin-08c-inprogress-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-08c-approved-list',
                title: 'Fin 08c Approved',
                name: 'Fin 08c Approved',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08/fin-08c-approved-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-08c-approved-list',
                title: 'Fin 08c Approved',
                name: 'Fin 08c Approved',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08/fin-08c-approved-list/older',
                breadcrumbs: true
              },
              {
                id: 'create-fin-08',
                title: 'Fin 08 Create',
                name: 'Fin 08 Create',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08-create-list/new',
                breadcrumbs: true
              },
              {
                id: 'create-fin-08',
                title: 'Fin 08 Create',
                name: 'Fin 08 Create',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08-create-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-08-inprogress-list',
                title: 'Fin 08 In Progress',
                name: 'Fin 08 In Progress',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08-inprogress-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-08-inprogress-list',
                title: 'Fin 08 In Progress',
                name: 'Fin 08 In Progress',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08-inprogress-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-08-approved-list',
                title: 'Fin 08 Approved',
                name: 'Fin 08 Approved',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08-approved-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-08-approved-list',
                title: 'Fin 08 Approved',
                name: 'Fin 08 Approved',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08-approved-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-08c-inv-exceptoin',
                title: 'Fin 08c Inv Exception',
                name: 'Fin 08c Inv Exception',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08/fin-08c-inv-exception',
                breadcrumbs: true
              },
              {
                id: 'fin-08-inv-exception',
                title: 'Fin 08 Inv Exception',
                name: 'Fin 08 Inv Exception',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-08-inv-exception',
                breadcrumbs: true
              },
              {
                id: 'create-fin-03',
                title: 'Fin 03 Create',
                name: 'Fin 03 Create',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-03-create-list/new',
                breadcrumbs: true
              },
              {
                id: 'create-fin-03',
                title: 'Fin 03 Create',
                name: 'Fin 03 Create',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-03-create-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-03-inprogress-list',
                title: 'Fin 03 In Progress',
                name: 'Fin 03 In Progress',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-03-inprogress-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-03-inprogress-list',
                title: 'Fin 03 In Progress',
                name: 'Fin 03 In Progress',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-03-inprogress-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-03-approved-list',
                title: 'Fin 03 Approved',
                name: 'Fin 03 Approved',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-03-approved-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-03-approved-list',
                title: 'Fin 03 Approved',
                name: 'Fin 03 Approved',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-03-approved-list/older',
                breadcrumbs: true
              },
              {
                id: 'create-fin-02',
                title: 'FIN 02 Create',
                name: 'FIN 02 Create',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-02-create-list/new',
                breadcrumbs: true
              },
              {
                id: 'create-fin-02',
                title: 'FIN 02 Create',
                name: 'FIN 02 Create',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-02-create-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-02-inprogress-list',
                title: 'FIN 02 In Progress',
                name: 'FIN 02 In Progress',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-02-inprogress-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-02-inprogress-list',
                title: 'FIN 02 In Progress',
                name: 'FIN 02 In Progress',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-02-inprogress-list/older',
                breadcrumbs: true
              },
              {
                id: 'fin-02-approved-list',
                title: 'FIN 02 Approved',
                name: 'FIN 02 Approved',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-02-approved-list/new',
                breadcrumbs: true
              },
              {
                id: 'fin-02-approved-list',
                title: 'FIN 02 Approved',
                name: 'FIN 02 Approved',
                type: 'item',
                url: '/transaction/fin-02-invoice/fin-02-approved-list/older',
                breadcrumbs: true
              },
            ]
          },
          {
            id: 'FIN-02b-list-page',
            title: 'FIN 02B',
            type: 'item',
            name: 'FIN 02B - Existing BME Maintenance Charges',
            // icon: 'feather icon-file-text',
            url: '/transaction/fin-02b-invoice/fin-02b-list-page',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'FIN-02b-invoice',
            title: 'FIN 02B',
            name: 'FIN 02B - Existing BME Maintenance Charges',
            type: 'collapse',
            url: '/transaction/fin-02b-invoice/fin-02b-list-page',
            breadcrumbs: true,
            hidden: true,
            // icon: 'feather icon-file-text',
            children: [
              {
                id: 'FIN-02b-create-list',
                title: 'FIN 02B Create',
                name: 'FIN 02B Create',
                type: 'item',
                url: '/transaction/fin-02b-invoice/fin-02b-create-list/new',
                breadcrumbs: true
              },
              {
                id: 'FIN-02b-create-list',
                title: 'FIN 02B Create',
                name: 'FIN 02B Create',
                type: 'item',
                url: '/transaction/fin-02b-invoice/fin-02b-create-list/older',
                breadcrumbs: true
              },
              {
                id: 'FIN-02b-inprogress-list',
                title: 'FIN 02B In Progress',
                name: 'FIN 02B In Progress',
                type: 'item',
                url: '/transaction/fin-02b-invoice/fin-02b-inprogress-list/new',
                breadcrumbs: true
              },
              {
                id: 'FIN-02b-inprogress-list',
                title: 'FIN 02B In Progress',
                name: 'FIN 02B In Progress',
                type: 'item',
                url: '/transaction/fin-02b-invoice/fin-02b-inprogress-list/older',
                breadcrumbs: true
              },
              {
                id: 'FIN-02b-approved-list',
                title: 'FIN 02B Approved',
                name: 'FIN 02B Approved',
                type: 'item',
                url: '/transaction/fin-02b-invoice/fin-02b-approved-list/new',
                breadcrumbs: true
              },
              {
                id: 'FIN-02b-approved-list',
                title: 'FIN 02B Approved',
                name: 'FIN 02B Approved',
                type: 'item',
                url: '/transaction/fin-02b-invoice/fin-02b-approved-list/older',
                breadcrumbs: true
              },
            ]
          },

          {
            id: 'fin-02b-equipment',
            title: 'FIN 02B Equipment',
            name: 'FIN 02B Equipment',
            type: 'item',
            url: '/transaction/fin-02b-equipment/fin-02b-create-equipment',
            breadcrumbs: true,
            hidden: false,
          },

          {
            id: 'fin-04-invoice',
            title: 'FIN 04',
            name: 'FIN 04 - Reimbursement of Site Construction Work',
            type: 'item',
            url: '/transaction/fin-04-invoice/fin-04-list-page',
            breadcrumbs: true,
            hidden: true,
            // icon: 'feather icon-file-text',
          },
          {
            id: 'fin-04-invoice',
            title: 'FIN 04',
            name: 'FIN 04 - Reimbursement of Site Construction Work',
            type: 'collapse',
            url: '/transaction/fin-04-invoice/fin-04-list-page',
            breadcrumbs: true,
            hidden: true,
            // icon: 'feather icon-file-text',
            children: [
              {
                id: 'fin-10b-create-list',
                title: 'Fin 10b Create',
                name: 'Fin 10b Create',
                type: 'item',
                url: '/transaction/fin-04-invoice/fin-10b-create',
                breadcrumbs: true
              },
              {
                id: 'fin-10b-list-inprogress',
                title: 'Fin 10b In Progress',
                name: 'Fin 10b In Progress',
                type: 'item',
                url: '/transaction/fin-04-invoice/fin-10b-list/inprogress',
                breadcrumbs: true
              },
              {
                id: 'fin-10b-list-approved',
                title: 'Fin 10b Approved',
                name: 'Fin 10b Approved',
                type: 'item',
                url: '/transaction/fin-04-invoice/fin-10b-approved-list/approved',
                breadcrumbs: true
              },
              {
                id: 'fin-04-create-list',
                title: 'FIN 04 Create',
                name: 'FIN 04 Create',
                type: 'item',
                url: '/transaction/fin-04-invoice/fin-04-create-list',
                breadcrumbs: true
              },
              {
                id: 'fin-04-inprogress-list',
                title: 'FIN 04 In Progress',
                name: 'FIN 04 In Progress',
                type: 'item',
                url: '/transaction/fin-04-invoice/fin-04-inprogress-list',
                breadcrumbs: true
              },
              {
                id: 'fin-04-approved-list',
                title: 'FIN 04 Approved',
                name: 'FIN 04 Approved',
                type: 'item',
                url: '/transaction/fin-04-invoice/fin-04-approved-list',
                breadcrumbs: true
              }
            ]
          },
          {

            id: 'fin-05a-list-page',
            title: 'FIN 05A',
            name: 'FIN 05A - Reimbursement of Site Conformity',
            type: 'item',
            // icon: 'feather icon-file-text',
            url: '/transaction/fin-05a-invoice/fin-05a-list-page',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'fin-05a-invoice',
            title: 'FIN 05A',
            name: 'FIN 05A - Reimbursement of Site Conformity',
            type: 'collapse',
            url: '/transaction/fin-05a-invoice/fin-05a-list-page',
            breadcrumbs: true,
            hidden: true,
            // icon: 'feather icon-file-text',
            children: [
              {
                id: 'FIN-05a-create',
                title: 'FIN 05A Create',
                name: 'FIN 05A Create',
                type: 'item',
                url: '/transaction/fin-05a-invoice/fin-05a-create',
                breadcrumbs: true
              },
              {
                id: 'FIN-05a-list-inprogress',
                title: 'FIN 05A In Progress',
                name: 'FIN 05A In Progress',
                type: 'item',
                url: '/transaction/fin-05a-invoice/fin-05a-inprogress-list',
                breadcrumbs: true
              },
              {
                id: 'FIN-05a-list-approved',
                title: 'FIN 05A Approved',
                name: 'FIN 05A Approved',
                type: 'item',
                url: '/transaction/fin-05a-invoice/fin-05a-approved-list',
                breadcrumbs: true
              },
            ]
          },
          {

            id: 'FIN-11-list-page',
            title: 'FIN 11',
            name: 'FIN 11 - Reimbursement of Concessional Elements',
            type: 'item',
            // icon: 'feather icon-file-text',
            url: '/transaction/fin-11-invoice/fin-11-list-page',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'FIN-11-invoice',
            title: 'FIN 11',
            name: 'FIN 11 - Reimbursement of Concessional Elements',
            type: 'collapse',
            url: '/transaction/fin-11-invoice/fin-11-list-page',
            breadcrumbs: true,
            hidden: true,
            // icon: 'feather icon-file-text',
            children: [
              {
                id: 'FIN-11-create',
                title: 'FIN 11 Create',
                name: 'FIN 11 Create',
                type: 'item',
                url: '/transaction/fin-11-invoice/fin-11-create',
                breadcrumbs: true
              },
              {
                id: 'FIN-11-list-inprogress',
                title: 'FIN 11 In Progress',
                name: 'FIN 11 In Progress',
                type: 'item',
                url: '/transaction/fin-11-invoice/fin-11-inprogress-list',
                breadcrumbs: true
              },
              {
                id: 'FIN-11-list-approved',
                title: 'FIN 11 Approved',
                name: 'FIN 11 Approved',
                type: 'item',
                url: '/transaction/fin-11-invoice/fin-11-approved-list',
                breadcrumbs: true
              },
            ]
          },

          {
            id: 'fin-09-invoice',
            title: 'FIN 09',
            name: 'FIN 09 - Penalty Charges',
            type: 'item',
            url: '/transaction/fin-09-invoice/fin-09-list-page',
            breadcrumbs: true,
            hidden: true,
            // icon: 'feather icon-file-text',
          },
          {
            id: 'fin-09-invoice',
            title: 'FIN 09',
            name: 'FIN 09 - Penalty Charges',
            type: 'collapse',
            url: '/transaction/fin-09-invoice/fin-09-list-page',
            breadcrumbs: true,
            hidden: true,
            // icon: 'feather icon-file-text',
            children: [
              {
                id: 'fin-09-create',
                title: 'FIN 09 Create',
                name: 'FIN 09 Create',
                type: 'item',
                url: '/transaction/fin-09-invoice/fin-09-create',
                breadcrumbs: true
              },
              {
                id: 'fin-09-inprogress-list',
                title: 'FIN 09 In Progress',
                name: 'FIN 09 In Progress',
                type: 'item',
                url: '/transaction/fin-09-invoice/fin-09-inprogress-list',
                breadcrumbs: true
              },
              {
                id: 'fin-09-approved-list',
                title: 'FIN 09 Approved',
                name: 'FIN 09 Approved',
                type: 'item',
                url: '/transaction/fin-09-invoice/fin-09-approved-list',
                breadcrumbs: true
              }
            ]
          },
          {
            id: 'credit-note',
            title: 'Credit Note',
            name: 'Credit Note',
            type: 'item',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'credit-note',
            title: 'Credit Note',
            name: 'Credit Note',
            type: 'collapse',
            hidden: true,
            children: [

              {
                id: 'fin09 Penalty',
                title: 'Fin09 Penalty',
                name: 'Fin09 Penalty',
                type: 'item',
                url: '/transaction/credit-note/fin09/created-list',
                breadcrumbs: true,
              },
              {
                id: 'cn-other-invoices',
                title: 'Other Invoices',
                name: 'Credit Note Other Invoices',
                type: 'item',
                url: '/transaction/credit-note/cn-other-invoices/cn-other-invoices',
                breadcrumbs: true,
              },


            ]
          },
          {
            id: 'fin09 Penalty',
            title: 'Credit Note-Fin09 Penalty',
            name: 'Credit Note Fin 09 Penalty',
            type: 'item',
            url: '/transaction/credit-note/fin09/created-list',
            breadcrumbs: true,
          },
          {
            id: 'cn-other-invoices',
            title: 'Credit Note-Other Invoices',
            name: 'Credit Note Other Invoices',
            type: 'item',
            url: '/transaction/credit-note/cn-other-invoices/cn-other-invoices',
            breadcrumbs: true,
          },
          {
            id: 'debit-note',
            title: 'Debit Note',
            name: 'Debit Note',
            type: 'item',
            // icon: 'feather icon-file-minus',
            url: '/transaction/debit-note/debit-note-create',
            breadcrumbs: true,
            hidden: true,

          },
        ]
      },
      {
        id: 'invoice settlement',
        title: 'Invoice Settlement',
        name: 'Invoice Settlement',
        type: 'collapse',
        icon: 'feather icon-file-minus',
        hidden: true,
        children: [
          {
            id: 'pai list',
            title: 'Bank Receipting',
            name: 'Bank Receipting',
            type: 'item',
            url: '/transaction/pai/pai-list',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'suspense account',
            title: 'Sundry Advances',
            name: 'Sundry Advances',
            type: 'item',
            url: '/transaction/sa/sa-list',
            breadcrumbs: true,
            hidden: true,
          },
          {
            id: 'Multiple Bank Receipting',
            title: 'Multiple Bank Receipting',
            name: 'Multiple Bank Receipting',
            type: 'item',
            url: '/transaction/pami',
            hidden: true,
          }
        ]
      },
      {
        id: 'report',
        title: 'Report',
        name: 'Report',
        type: 'collapse',
        icon: 'feather icon-briefcase',
        hidden: true,
        children: [
          {
            id: 'invoice',
            title: 'Invoice Report',
            name: 'Invoice Report',
            type: 'item',
            url: '/report/invoice/invoice-list',
            breadcrumbs: false,
            hidden: true,
          },
          {
            id: 'Summary Report',
            title: 'Invoice Summary Report',
            name: 'Invoice Summary Report',
            type: 'item',
            url: '/report/invoice/summary-report',
            breadcrumbs: false,
            hidden: true,
          },

          {
            id: 'invoice-number-search',
            title: 'Collection Report',
            name: 'Collection Report',
            type: 'item',
            url: '/report/invoice/invoice-number-search',
            breadcrumbs: false,
            hidden: true,
          },


          {
            id: 'Collection Summary Report',
            title: 'Collection Summary Report',
            name: 'Collection Summary Report',
            type: 'item',
            url: '/report/invoice/collection-summary-report',
            breadcrumbs: false,
            hidden: true,
          },
          {
            id: 'Ageing Report',
            title: 'Ageing Report',
            name: 'Ageing Report',
            type: 'item',
            url: '/report/invoice/ageing-report',
            breadcrumbs: false,
            hidden: true,
          },
          {
            id: 'Credit&Debit note list',
            title: 'Credit&Debit note Report',
            name: 'Credit&Debit note Report',
            type: 'item',
            url: '/report/invoice/credit-debit-note-list',
            breadcrumbs: false,
            hidden: true,
          }
          // ,
          // {
          //   id: 'Equipment Billing History Mock',
          //   title: 'Equipment Billing History Mock',
          //   name: 'Equipment Billing History Mock',
          //   type: 'item',
          //   url: '/report/invoice/equipment-billing-history',
          //   breadcrumbs: false,
          //   hidden: true,
          // }

        ]
      },
      {
        id: 'special-report',
        title: 'Special Report',
        name: 'Special-Approval',
        type: 'collapse',
        icon: 'feather icon-layers',
        breadcrumbs: true,
        hidden: true,
        // url: '/special-approval',rental-equipment-info
        children: [
          {
            id: 'special-approval',
            title: 'Special-Credit Note-Fin09 Penalty',
            name: 'Credit Note-Fin09 Penalty - Special Approval',
            type: 'item',
            url: '/special-approval',
            breadcrumbs: false,
            hidden: true,
          },
          // {
          //   id: 'Process',
          //   title: 'Collection Reversal',
          //   name: 'Collection Reversal  ',
          //   type: 'item',
          //   url: '/transaction/paymentreversal',
          //   breadcrumbs: true,
          //   hidden: false,
          // },
          // {
          //   id: 'rental-info',
          //   title: 'Rental Equipment Info',
          //   name: 'Rental Equipment Info',
          //   type: 'item',
          //   url: '/special-approval/rental-equipment-info',
          //   breadcrumbs: false,
          //   hidden: false,
          // }
        ]
      },
    ]
  }

];

@Injectable({ providedIn: 'root' })
export class NavigationItem {
  constructor(private userManagementService: UserManagementService, private pagesService: PagesService) { }
  public get() {
    this.userManagementService.getAllUserGroupMenuMappingByGroupId(this.pagesService.getMenuMappingPriorityGroupId()).subscribe((datas) => {
      NavigationItems.forEach((navigation => {
        navigation.children.forEach((mainMenu) => {
          if (mainMenu.children != undefined) {
            mainMenu.children.forEach((subMenu) => {
              datas.forEach((data => {
                if (subMenu.type == 'item') {
                  if (data.menuName == subMenu.title) {
                    subMenu.hidden = false
                    //  
                    mainMenu.hidden = false
                  }
                }
              }))
            })
          } else {
            if (mainMenu.title == 'Dashboard') {
              datas.forEach((data => {

                if (data.menuName == 'Invoice Generation' || data.menuName == 'Revenue Analysis' || data.menuName == 'Account Receivables' || data.menuName == 'Retention' || data.menuName == 'Unadjusted Penalities' || data.menuName == 'Backlog Invoices' || data.menuName == 'EIS') {
                  mainMenu.hidden = false
                }
              }))
            }
          }
        })
      }))
    })
    return NavigationItems;
  }
}