import { Component, DoCheck, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { GradientConfig } from '../../../../../app-config';
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],

})
export class NavRightComponent implements OnInit, DoCheck {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public gradientConfig: any;
  public userName: string;

  constructor(private _router: Router) {
    this.visibleUserList = false;
    this.chatMessage = false;
    this.gradientConfig = GradientConfig.config;

  }

  ngOnInit() {

    if(localStorage.getItem('currentUser') != null){
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = currentUser.name;
    }
  }


  ngDoCheck() {
    if (document.querySelector('body').classList.contains('elite-rtl')) {
      this.gradientConfig['rtl-layout'] = true;
    } else {
      this.gradientConfig['rtl-layout'] = false;
    }
  }

  logout() {
    Swal.fire({
      title: 'Are you sure?',
      // text: 'You will not be able to recover this imaginary file!',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Cancel',
      cancelButtonText: 'Logout'
    }).then((result) => {
      if (result == true) {
        Swal.fire(
          'Deleted!',
          'Your imaginary file has been deleted.',
          'success'
        )
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        localStorage.removeItem('currentUser');
        this._router.navigate(['/auth/login']);
        console.log("loged out...",)
        Swal.fire(
          // 'Cancelled',
          'Logout is Succesfull',
          // 'error'
        )
      }
    })   
     // this._router.navigate(['/auth/login']);
    // localStorage.removeItem('currentUser');
  }

  changePassword() {
    this._router.navigate(['/auth/change-password']);
  }

}
